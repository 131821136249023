var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_c('div',{staticClass:"swiper"},[_c('div',{staticClass:"swiper-wrapper"},[_vm._m(0),_c('div',{class:`swiper-slide slide2 slideHead ${_vm.animationList.indexOf('slide2Animation') > -1 ? 'slide2Animation' : ''}`},[_vm._m(1),_c('div',{staticClass:"title"},[_vm._v("国内有影响力的混合所有制企业数字科技+数字经济创新服务")]),_vm._m(2),_vm._m(3)]),_c('div',{class:`swiper-slide slide3 slideHead ${_vm.animationList.indexOf('slide3Animation') > -1 ? 'slide3Animation' : ''}`},[_vm._m(4),_vm._m(5),_c('div',{staticClass:"slide3Bg"}),_c('img',{staticClass:"slide3BgImg",attrs:{"src":require("../assets/slide3Bg.png"),"alt":""}})]),_c('div',{class:`swiper-slide slide4 slideHead ${_vm.animationList.indexOf('slide4Animation') > -1 ? 'slide4Animation' : ''}`},[_vm._m(6),_vm._m(7)]),_c('div',{class:`swiper-slide slide5 slideHead ${_vm.animationList.indexOf('slide5Animation') > -1 ? 'slide5Animation' : ''}`},[_vm._m(8),_c('div',{staticClass:"whitBg"}),_vm._m(9)]),_vm._m(10),_vm._m(11),_vm._m(12),_vm._m(13),_vm._m(14),_vm._m(15),_vm._m(16)])]),_vm._m(17)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"swiper-slide slide1"},[_c('h1',[_c('img',{attrs:{"src":require("../assets/logo.png"),"alt":"","width":"100%"}})]),_c('p',{staticClass:"1"},[_vm._v("数据资产入表咨询服务项目简介")]),_c('span',[_vm._v("www.51haohuo.com")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"head"},[_c('p',[_vm._v("公司简介")]),_c('img',{attrs:{"src":require("../assets/logo.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"showImg"},[_c('img',{attrs:{"src":require("../assets/slide2Img.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('h4',[_c('span',[_vm._v("价值观")]),_vm._v(" 合规成就品牌，科技铸就未来")]),_c('p',[_c('span',[_vm._v("好活科技成立于2015年，是一家以")]),_vm._v("大数据、云计算、区块链、人工智能"),_c('span',[_vm._v("等")]),_vm._v("数字技术"),_c('span',[_vm._v("驱动的")]),_vm._v("数字经济创新服务科技企业，"),_c('span',[_vm._v("重点")]),_vm._v(" 聚焦数字经济领域，"),_c('span',[_vm._v("提供")]),_vm._v("数字化就业"),_c('span',[_vm._v("服务")]),_vm._v("、产业数字化"),_c('span',[_vm._v("服务")]),_vm._v("、数据要素生态产业园"),_c('span',[_vm._v("建设与运营、")]),_vm._v("数据要素人才"),_c('span',[_vm._v("产教融合培养")]),_vm._v(" 、数据资产化"),_c('span',[_vm._v("综合服务。服务企业从数字化改造升级为入口，构建以形式有价值数据资产为目标的数据化要素化平台体系，帮助")]),_vm._v("传统产业实现数据要素化升级 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"head"},[_c('p',[_vm._v("项目介绍")]),_c('img',{attrs:{"src":require("../assets/logo.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"contentBox"},[_c('h1',[_vm._v("数据资产入表咨询服务")]),_c('p',[_c('b',[_vm._v("数据资源盘点")]),_c('span',[_vm._v("对企业持有的数据资源进行全面梳理和盘点，明确待入表数据资源的来源、类型、权属、数量、质量、使用情况等信息")])]),_c('p',[_c('b',[_vm._v("数据资源合规确权")]),_c('span',[_vm._v("对企业持有的数据资源的合规性进行评估，帮助企业明确数据资源持有权、数据加工使用权、数据产品经营权等权利归属")])]),_c('p',[_c('b',[_vm._v("数据资源入表辅导")]),_c('span',[_vm._v("辅导财务部门对拟入表数据资源的相关交易和事项进行会计确认、计量和报告，并提供财会相关法律法规、政策规定以及“指引”的解读")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"head"},[_c('p',[_vm._v("服务对象")]),_c('img',{attrs:{"src":require("../assets/logo.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"item item1"},[_c('div',{staticClass:"whitBg"}),_c('h4',[_vm._v("国企和行政事业单位")]),_c('p',[_vm._v("响应国家政策号召，满足财政部对于国有企业和行政事业单位数据资产纳入管理的要求")])]),_c('div',{staticClass:"item item2"},[_c('div',{staticClass:"whitBg"}),_c('h4',[_vm._v("数字化转型领先企业")]),_c('p',[_vm._v("体现企业数据驱动式转型成果，匹配企业在行业内领先的“数字化人设”")])]),_c('div',{staticClass:"item item3"},[_c('div',{staticClass:"whitBg"}),_c('h4',[_vm._v("数据资源型企业")]),_c('p',[_vm._v("体现企业稀缺性和排他性数据资源的禀赋，彰显企业数据价值挖掘和数据资产化潜力")])]),_c('div',{staticClass:"item item4"},[_c('div',{staticClass:"whitBg"}),_c('h4',[_vm._v("上市公司")]),_c('p',[_vm._v("应满足财务合规要求，使数据资源在企业报表中得到合理体现，反应相应的资产和利润成果")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"head"},[_c('p',[_vm._v("服务价值")]),_c('img',{attrs:{"src":require("../assets/logo.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"icons"},[_c('div',{staticClass:"iconsItem flexStart"},[_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("../assets/slide5icon1.png"),"alt":""}})]),_c('span',[_vm._v("降低资产负债比")]),_c('i')]),_c('div',{staticClass:"iconsItem flexEnd"},[_c('span',[_vm._v("提升净利润")]),_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("../assets/slide5icon2.png"),"alt":""}})]),_c('i')]),_c('div',{staticClass:"iconsItem flexStart"},[_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("../assets/slide5icon3.png"),"alt":""}})]),_c('span',[_vm._v("债券融资")]),_c('i')]),_c('div',{staticClass:"iconsItem flexEnd"},[_c('span',[_vm._v("快速响应新质生产力")]),_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("../assets/slide5icon4.png"),"alt":""}})]),_c('i')]),_c('div',{staticClass:"iconsItem flexStart"},[_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("../assets/slide5icon5.png"),"alt":""}})]),_c('span',[_vm._v("新的发展方向与机遇")]),_c('i')]),_c('div',{staticClass:"iconsItem flexEnd"},[_c('span',[_vm._v("经营数据开拓变现路径")]),_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("../assets/slide5icon6.png"),"alt":""}})]),_c('i')])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"swiper-slide slide6 slideHead sildeNoBg"},[_c('div',{staticClass:"head"},[_c('p',[_vm._v("数据要素领域专业资质")]),_c('img',{attrs:{"src":require("../assets/logo.png"),"alt":""}})]),_c('img',{staticClass:"img",attrs:{"src":require("../assets/slide6Bg2.png"),"alt":""}}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"imgData"},[_c('div',{staticClass:"contentText"},[_vm._v(" 好活（重庆）网络科技有限公司"),_c('br'),_vm._v(" 获多地数据交易所数商认证 ")]),_c('div',{staticClass:"imgList"},[_c('div',{staticClass:"imgItem"},[_c('img',{attrs:{"src":require("../assets/slide6Img1.png"),"alt":""}}),_c('p',[_vm._v("西部大数据交易中心")]),_c('span',[_vm._v("数据商")])]),_c('div',{staticClass:"imgItem"},[_c('img',{attrs:{"src":require("../assets/slide6Img2.png"),"alt":""}}),_c('p',[_vm._v("深圳大数据交易所")]),_c('span',[_vm._v("数据商")])]),_c('div',{staticClass:"imgItem"},[_c('img',{attrs:{"src":require("../assets/slide6Img3.png"),"alt":""}}),_c('p'),_c('span',[_vm._v("技术型数据商")]),_c('p',[_vm._v("和"),_c('span',[_vm._v("应用型数据商")])])]),_c('div',{staticClass:"imgItem"},[_c('img',{attrs:{"src":require("../assets/slide6Img4.png"),"alt":""}}),_c('p',[_vm._v("贵阳大数据交易所")]),_c('span',[_vm._v("数据中介凭证")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"swiper-slide slide7 slideHead sildeNoBg"},[_c('div',{staticClass:"head"},[_c('p',[_vm._v("自助知识产权")]),_c('img',{attrs:{"src":require("../assets/logo.png"),"alt":""}})]),_c('img',{staticClass:"img",attrs:{"src":require("../assets/slide6Bg2.png"),"alt":""}}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"imgData"},[_c('div',{staticClass:"contentText"},[_c('p',[_vm._v("好活集团取得"),_c('b',[_vm._v("发明专利")]),_c('span',[_vm._v("27个")]),_vm._v("，"),_c('b',[_vm._v("实用新型")]),_c('span',[_vm._v("142个")]),_vm._v("，"),_c('b',[_vm._v("软著")]),_c('span',[_vm._v("386个")]),_vm._v("。 其中重庆好活坚持自主研发，持续推动核心技术原始创新，取得多项"),_c('b',[_vm._v("数据要素（新要素）相关知识产权。")])])]),_c('div',{staticClass:"imgItem"},[_c('img',{attrs:{"src":require("../assets/slide7Img1.png"),"alt":""}})]),_c('div',{staticClass:"imgItem"},[_c('img',{attrs:{"src":require("../assets/slide7Img2.png"),"alt":""}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"swiper-slide slide8 slideHead sildeNoBg"},[_c('div',{staticClass:"head"},[_c('p',[_vm._v("荣誉资质")]),_c('img',{attrs:{"src":require("../assets/logo.png"),"alt":""}})]),_c('img',{staticClass:"img",attrs:{"src":require("../assets/slide6Bg2.png"),"alt":""}}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"imgItem"},[_c('img',{attrs:{"src":require("../assets/slide8Img1.png"),"alt":""}}),_c('p',[_vm._v("被"),_c('span',[_vm._v("国家信息中心")]),_vm._v("评为"),_c('span',[_vm._v("分享经")])]),_c('span',[_vm._v("济案例研究基地")])]),_c('div',{staticClass:"imgItem"},[_c('img',{attrs:{"src":require("../assets/slide8Img2.png"),"alt":""}}),_c('p',[_vm._v("被"),_c('span',[_vm._v("国家发展和改革委员会")]),_vm._v("评")]),_c('p',[_vm._v("为"),_c('span',[_vm._v("中国共享经济典型平台")])])]),_c('div',{staticClass:"imgItem"},[_c('img',{attrs:{"src":require("../assets/slide8Img2.png"),"alt":""}}),_c('p',[_vm._v("被"),_c('span',[_vm._v("中国信息研究院")]),_vm._v("评为"),_c('span',[_vm._v("双创")])]),_c('span',[_vm._v("支撑平台合作伙伴")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"swiper-slide slide8 slideHead sildeNoBg"},[_c('div',{staticClass:"head"},[_c('p',[_vm._v("荣誉资质")]),_c('img',{attrs:{"src":require("../assets/logo.png"),"alt":""}})]),_c('img',{staticClass:"img",attrs:{"src":require("../assets/slide6Bg2.png"),"alt":""}}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"imgItem"},[_c('img',{attrs:{"src":require("../assets/slide8Img4.png"),"alt":""}}),_c('p',[_vm._v("被评为")]),_c('span',[_vm._v("“2021中国服务业企业500强”")])]),_c('div',{staticClass:"imgItem"},[_c('img',{attrs:{"src":require("../assets/slide8Img5.png"),"alt":""}}),_c('p',[_vm._v("被认定为"),_c('span',[_vm._v("国家级科技型中小")])]),_c('p',[_c('span',[_vm._v("企业")])])]),_c('div',{staticClass:"imgItem"},[_c('img',{attrs:{"src":require("../assets/slide8Img6.png"),"alt":""}}),_c('p',[_vm._v("入选"),_c('span',[_vm._v("DAC全球数据资产理事会")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"swiper-slide slide8 slide9 slideHead sildeNoBg"},[_c('div',{staticClass:"head"},[_c('p',[_vm._v("奖项荣誉")]),_c('img',{attrs:{"src":require("../assets/logo.png"),"alt":""}})]),_c('img',{staticClass:"img",attrs:{"src":require("../assets/slide6Bg2.png"),"alt":""}}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"imgItem"},[_c('img',{attrs:{"src":require("../assets/slide8Img7.png"),"alt":""}}),_c('p',[_vm._v("2020年11月深圳人力资源数字化科技大赛，好活科")]),_c('p',[_vm._v("技荣获应用赛二等奖")])]),_c('div',{staticClass:"imgItem"},[_c('img',{attrs:{"src":require("../assets/slide8Img8.png"),"alt":""}}),_c('p',[_vm._v("2021年5月，数博会领先科技成果发布活动，好活")]),_c('p',[_vm._v("科技荣获“2021领先科技成果优秀项目”荣誉")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"swiper-slide slide8 slide9 slideHead sildeNoBg"},[_c('div',{staticClass:"head"},[_c('p',[_vm._v("奖项荣誉")]),_c('img',{attrs:{"src":require("../assets/logo.png"),"alt":""}})]),_c('img',{staticClass:"img",attrs:{"src":require("../assets/slide6Bg2.png"),"alt":""}}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"imgItem"},[_c('img',{attrs:{"src":require("../assets/slide8Img9.png"),"alt":""}}),_c('p',[_vm._v("2021年12月，广东“众创杯”创业创新大赛，好活科")]),_c('p',[_vm._v("技暂获科技海归领航赛金奖")])]),_c('div',{staticClass:"imgItem"},[_c('img',{attrs:{"src":require("../assets/slide8Img10.png"),"alt":""}}),_c('p',[_vm._v("2024年8月，在“2024全球数据资产大会”上，好活")]),_c('p',[_vm._v("科技荣获“数据要素市场化配置改革先进示范模式”十")]),_c('p',[_vm._v("佳荣誉，成为数据要素行业发展的典范。")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"swiper-slide slide10 sildeNoBg"},[_c('img',{staticClass:"img",attrs:{"src":require("../assets/slide6Bg2.png"),"alt":""}}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":require("../assets/logo.png"),"alt":""}}),_c('p',[_vm._v("数据要素产业园建设与运营、数据资产化服务、")]),_c('p',[_vm._v("数据技术服务、公共数据运营赋能")])]),_c('div',{staticClass:"QRCode"},[_c('div',{staticClass:"itemImg"},[_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("../assets/qrcodeImg1.png"),"alt":""}}),_c('span',[_vm._v("公众号")])]),_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("../assets/qrcodeImg2.png"),"alt":""}}),_c('span',[_vm._v("企微服务号")])])]),_c('div',{staticClass:"tips"},[_c('h1',[_vm._v("扫一扫，提供更多支持")]),_c('p',[_vm._v("好活（重庆）网络科技有限公司")])])]),_c('div',{staticClass:"bottom"},[_c('p',[_c('i'),_vm._v("重庆两江新区寸滩街道金渝大道153号4幢10-1至10-20、11-1至11-20")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tipsIcons"},[_c('img',{staticClass:"iconsImg1",attrs:{"src":require("../assets/icon1.png"),"alt":""}}),_c('img',{staticClass:"iconsImg2",attrs:{"src":require("../assets/icon1.png"),"alt":""}})])
}]

export { render, staticRenderFns }