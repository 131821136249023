<template>
  <div class="home">
    <div class="swiper">
      <div class="swiper-wrapper">
        <div class="swiper-slide slide1">
          <h1><img src="../assets/logo.png" alt="" width="100%"></h1>
          <p class="1">数据资产入表咨询服务项目简介</p>
          <span>www.51haohuo.com</span>
        </div>
        <div :class="`swiper-slide slide2 slideHead ${animationList.indexOf('slide2Animation') > -1 ? 'slide2Animation' : ''}`">
          <div class="head">
            <p>公司简介</p>
            <img src="../assets/logo.png" alt="">
          </div>
          <div class="title">国内有影响力的混合所有制企业数字科技+数字经济创新服务</div>
          <div class="showImg"><img src="../assets/slide2Img.png" alt=""></div>
          <div class="content">
            <h4><span>价值观</span> 合规成就品牌，科技铸就未来</h4>
            <p><span>好活科技成立于2015年，是一家以</span>大数据、云计算、区块链、人工智能<span>等</span>数字技术<span>驱动的</span>数字经济创新服务科技企业，<span>重点</span>
              聚焦数字经济领域，<span>提供</span>数字化就业<span>服务</span>、产业数字化<span>服务</span>、数据要素生态产业园<span>建设与运营、</span>数据要素人才<span>产教融合培养</span>
              、数据资产化<span>综合服务。服务企业从数字化改造升级为入口，构建以形式有价值数据资产为目标的数据化要素化平台体系，帮助</span>传统产业实现数据要素化升级
            </p>
          </div>
        </div>
        <div :class="`swiper-slide slide3 slideHead ${animationList.indexOf('slide3Animation') > -1 ? 'slide3Animation' : ''}`">
          <div class="head">
            <p>项目介绍</p>
            <img src="../assets/logo.png" alt="">
          </div>
          <div class="content">
            <div class="contentBox">
              <h1>数据资产入表咨询服务</h1>
              <p>
                <b>数据资源盘点</b>
                <span>对企业持有的数据资源进行全面梳理和盘点，明确待入表数据资源的来源、类型、权属、数量、质量、使用情况等信息</span>
              </p>
              <p>
                <b>数据资源合规确权</b>
                <span>对企业持有的数据资源的合规性进行评估，帮助企业明确数据资源持有权、数据加工使用权、数据产品经营权等权利归属</span>
              </p>
              <p>
                <b>数据资源入表辅导</b>
                <span>辅导财务部门对拟入表数据资源的相关交易和事项进行会计确认、计量和报告，并提供财会相关法律法规、政策规定以及“指引”的解读</span>
              </p>
            </div>
          </div>
          <div class="slide3Bg"></div>
          <img src="../assets/slide3Bg.png" alt="" class="slide3BgImg">
        </div>
        <div :class="`swiper-slide slide4 slideHead ${animationList.indexOf('slide4Animation') > -1 ? 'slide4Animation' : ''}`">
          <div class="head">
            <p>服务对象</p>
            <img src="../assets/logo.png" alt="">
          </div>
          <div class="content">
            <div class="item item1">
              <div class="whitBg"></div>
              <h4>国企和行政事业单位</h4>
              <p>响应国家政策号召，满足财政部对于国有企业和行政事业单位数据资产纳入管理的要求</p>
            </div>
            <div class="item item2">
              <div class="whitBg"></div>
              <h4>数字化转型领先企业</h4>
              <p>体现企业数据驱动式转型成果，匹配企业在行业内领先的“数字化人设”</p>
            </div>
            <div class="item item3">
              <div class="whitBg"></div>
              <h4>数据资源型企业</h4>
              <p>体现企业稀缺性和排他性数据资源的禀赋，彰显企业数据价值挖掘和数据资产化潜力</p>
            </div>
            <div class="item item4">
              <div class="whitBg"></div>
              <h4>上市公司</h4>
              <p>应满足财务合规要求，使数据资源在企业报表中得到合理体现，反应相应的资产和利润成果</p>
            </div>
          </div>
        </div>
        <div :class="`swiper-slide slide5 slideHead ${animationList.indexOf('slide5Animation') > -1 ? 'slide5Animation' : ''}`">
          <div class="head">
            <p>服务价值</p>
            <img src="../assets/logo.png" alt="">
          </div>
          <div class="whitBg"></div>
          <div class="content">
            <div class="icons">
              <div class="iconsItem flexStart">
                <div class="item">
                  <img src="../assets/slide5icon1.png" alt="">
                </div>
                <span>降低资产负债比</span>
                <i></i>
              </div>
              <div class="iconsItem flexEnd">
                <span>提升净利润</span>
                <div class="item">
                  <img src="../assets/slide5icon2.png" alt="">
                </div>
                <i></i>
              </div>
              <div class="iconsItem flexStart">
                <div class="item">
                  <img src="../assets/slide5icon3.png" alt="">
                </div>
                <span>债券融资</span>
                <i></i>
              </div>
              <div class="iconsItem flexEnd">
                <span>快速响应新质生产力</span>
                <div class="item">
                  <img src="../assets/slide5icon4.png" alt="">
                </div>
                <i></i>
              </div>
              <div class="iconsItem flexStart">
                <div class="item">
                  <img src="../assets/slide5icon5.png" alt="">
                </div>
                <span>新的发展方向与机遇</span>
                <i></i>
              </div>
              <div class="iconsItem flexEnd">
                <span>经营数据开拓变现路径</span>
                <div class="item">
                  <img src="../assets/slide5icon6.png" alt="">
                </div>
                <i></i>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-slide slide6 slideHead sildeNoBg">
          <div class="head">
            <p>数据要素领域专业资质</p>
            <img src="../assets/logo.png" alt="">
          </div>
          <img src="../assets/slide6Bg2.png" alt="" class="img"/>
          <div class="content">
            <div class="imgData">
              <div class="contentText">
                好活（重庆）网络科技有限公司<br/>
                获多地数据交易所数商认证
              </div>
              <div class="imgList">
                <div class="imgItem">
                  <img src="../assets/slide6Img1.png" alt=""/>
                  <p>西部大数据交易中心</p>
                  <span>数据商</span>
                </div>
                <div class="imgItem">
                  <img src="../assets/slide6Img2.png" alt=""/>
                  <p>深圳大数据交易所</p>
                  <span>数据商</span>
                </div>
                <div class="imgItem">
                  <img src="../assets/slide6Img3.png" alt=""/>
                  <p></p>
                  <span>技术型数据商</span>
                  <p>和<span>应用型数据商</span></p>
                </div>
                <div class="imgItem">
                  <img src="../assets/slide6Img4.png" alt=""/>
                  <p>贵阳大数据交易所</p>
                  <span>数据中介凭证</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-slide slide7 slideHead sildeNoBg">
          <div class="head">
            <p>自助知识产权</p>
            <img src="../assets/logo.png" alt="">
          </div>
          <img src="../assets/slide6Bg2.png" alt="" class="img"/>
          <div class="content">
            <div class="imgData">
              <div class="contentText">
                <p>好活集团取得<b>发明专利</b><span>27个</span>，<b>实用新型</b><span>142个</span>，<b>软著</b><span>386个</span>。
                  其中重庆好活坚持自主研发，持续推动核心技术原始创新，取得多项<b>数据要素（新要素）相关知识产权。</b></p>
              </div>
              <div class="imgItem">
                <img src="../assets/slide7Img1.png" alt=""/>
              </div>
              <div class="imgItem">
                <img src="../assets/slide7Img2.png" alt=""/>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-slide slide8 slideHead sildeNoBg">
          <div class="head">
            <p>荣誉资质</p>
            <img src="../assets/logo.png" alt="">
          </div>
          <img src="../assets/slide6Bg2.png" alt="" class="img"/>
          <div class="content">
            <div class="imgItem">
              <img src="../assets/slide8Img1.png" alt=""/>
              <p>被<span>国家信息中心</span>评为<span>分享经</span></p>
              <span>济案例研究基地</span>
            </div>
            <div class="imgItem">
              <img src="../assets/slide8Img2.png" alt=""/>
              <p>被<span>国家发展和改革委员会</span>评</p>
              <p>为<span>中国共享经济典型平台</span></p>
            </div>
            <div class="imgItem">
              <img src="../assets/slide8Img2.png" alt=""/>
              <p>被<span>中国信息研究院</span>评为<span>双创</span></p>
              <span>支撑平台合作伙伴</span>
            </div>
          </div>
        </div>
        <div class="swiper-slide slide8 slideHead sildeNoBg">
          <div class="head">
            <p>荣誉资质</p>
            <img src="../assets/logo.png" alt="">
          </div>
          <img src="../assets/slide6Bg2.png" alt="" class="img"/>
          <div class="content">
            <div class="imgItem">
              <img src="../assets/slide8Img4.png" alt=""/>
              <p>被评为</p>
              <span>“2021中国服务业企业500强”</span>
            </div>
            <div class="imgItem">
              <img src="../assets/slide8Img5.png" alt=""/>
              <p>被认定为<span>国家级科技型中小</span></p>
              <p><span>企业</span></p>
            </div>
            <div class="imgItem">
              <img src="../assets/slide8Img6.png" alt=""/>
              <p>入选<span>DAC全球数据资产理事会</span></p>
            </div>
          </div>
        </div>
        <div class="swiper-slide slide8 slide9 slideHead sildeNoBg">
          <div class="head">
            <p>奖项荣誉</p>
            <img src="../assets/logo.png" alt="">
          </div>
          <img src="../assets/slide6Bg2.png" alt="" class="img"/>
          <div class="content">
            <div class="imgItem">
              <img src="../assets/slide8Img7.png" alt=""/>
              <p>2020年11月深圳人力资源数字化科技大赛，好活科</p>
              <p>技荣获应用赛二等奖</p>
            </div>
            <div class="imgItem">
              <img src="../assets/slide8Img8.png" alt=""/>
              <p>2021年5月，数博会领先科技成果发布活动，好活</p>
              <p>科技荣获“2021领先科技成果优秀项目”荣誉</p>
            </div>
          </div>
        </div>
        <div class="swiper-slide slide8 slide9 slideHead sildeNoBg">
          <div class="head">
            <p>奖项荣誉</p>
            <img src="../assets/logo.png" alt="">
          </div>
          <img src="../assets/slide6Bg2.png" alt="" class="img"/>
          <div class="content">
            <div class="imgItem">
              <img src="../assets/slide8Img9.png" alt=""/>
              <p>2021年12月，广东“众创杯”创业创新大赛，好活科</p>
              <p>技暂获科技海归领航赛金奖</p>
            </div>
            <div class="imgItem">
              <img src="../assets/slide8Img10.png" alt=""/>
              <p>2024年8月，在“2024全球数据资产大会”上，好活</p>
              <p>科技荣获“数据要素市场化配置改革先进示范模式”十</p>
              <p>佳荣誉，成为数据要素行业发展的典范。</p>
            </div>
          </div>
        </div>
        <div class="swiper-slide slide10 sildeNoBg">
          <img src="../assets/slide6Bg2.png" alt="" class="img"/>
          <div class="content">
            <div class="logo">
              <img src="../assets/logo.png" alt="">
              <p>数据要素产业园建设与运营、数据资产化服务、</p>
              <p>数据技术服务、公共数据运营赋能</p>
            </div>
            <div class="QRCode">
              <div class="itemImg">
                <div class="item">
                  <img src="../assets/qrcodeImg1.png" alt="">
                  <span>公众号</span>
                </div>
                <div class="item">
                  <img src="../assets/qrcodeImg2.png" alt=""/>
                  <span>企微服务号</span>
                </div>
              </div>
              <div class="tips">
                <h1>扫一扫，提供更多支持</h1>
                <p>好活（重庆）网络科技有限公司</p>
              </div>
            </div>
            <div class="bottom">
              <p><i></i>重庆两江新区寸滩街道金渝大道153号4幢10-1至10-20、11-1至11-20</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tipsIcons">
      <img src="../assets/icon1.png" alt="" class="iconsImg1">
      <img src="../assets/icon1.png" alt="" class="iconsImg2">
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper';
import 'swiper/css/swiper.css';
export default {
  name: 'HomeView',
  data() {
    return {
      animationList: [],
      animationObj: {
        0:'slide1Animation',
        1:'slide2Animation',
        2:'slide3Animation',
        3:'slide4Animation',
        4:'slide5Animation',
        5:'slide6Animation',
        6:'slide7Animation',
        7:'slide8Animation',
        8:'slide9Animation',
        9:'slide10Animation',
        10:'slide11Animation'
      },
    }
  },
  mounted() {
    const that = this;
    var mySwiper = new Swiper ('.swiper', {
      initialSlide: 0,
      direction: 'vertical', // 垂直切换选项
      loop: false, // 循环模式选项
      on:{
        slideChange: function(e){
          if (that.animationList.indexOf(that.animationObj[this.activeIndex]) == -1) {
            that.animationList.push(that.animationObj[this.activeIndex])
          }
        },
      },
  })  
  },
  components: {
  },
};
</script>
<style>
@import url(../assets/style.css);
@import url(../assets/animation.css);
</style>
